import { Container, Grid, Box, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import { useTheme } from '@mui/material/styles';

const HomePage = () => {
  const auth = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const currentUser = auth ? auth.currentUser : null;

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <Container maxWidth={false} className="homeSplash">
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Typography variant="h1">Bienvenue</Typography>
          <Typography variant="body1" color="white">
            {currentUser?.data?.organization?.name}
          </Typography>
          <Grid container direction={isMobile ? 'column' : 'row'} justifyContent="space-evenly" alignItems="center">
            <Box
              className="linkBox"
              style={{ marginBottom: isMobile ? theme.spacing(2) : 0, cursor: 'pointer' }}
              onClick={() => handleNavigation('/deals-list')}
            >
              Consulter la liste des cotations
            </Box>
            <Box
              className="linkBox"
              style={{ marginBottom: isMobile ? theme.spacing(2) : 0, cursor: 'pointer' }}
              onClick={() => handleNavigation('/cotation')}
            >
              Faire une demande de cotation
            </Box>
            <Box
              className="linkBox"
              style={{
                flexDirection: 'column',
                padding: '2.2em 0',
                justifyContent: 'center',
                marginBottom: isMobile ? theme.spacing(2) : 0,
                cursor: 'pointer',
                position: 'relative',
              }}
              onClick={() => handleNavigation('/form-cg')}
            >
              <Box
                sx={{
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              ></Box>
              Faire une demande de CG
              <Typography
                variant="body1"
                color="black"
                textAlign={'center'}
                sx={{ marginTop: 1, fontSize: '14px', display: 'block', width: '100%' }}
              >
                Promo : 10 € HT
              </Typography>
            </Box>
            <Box
              className="linkBox"
              style={{
                flexDirection: 'column',
                justifyContent: 'center',
                marginBottom: isMobile ? theme.spacing(2) : 0,
                cursor: 'pointer',
                position: 'relative',
                textDecoration: 'none',
              }}
              component="a"
              href="https://form.typeform.com/to/Xx5CeYaU"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box
                sx={{
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: '0px',
                    left: '-30px',
                    backgroundColor: '#FF6060',
                    transform: 'rotate(-45deg)',
                    padding: '0 0.5em',
                  }}
                >
                  Nouveau
                </Typography>
              </Box>
              Déclaration d'achat
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HomePage;
